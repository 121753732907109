<template>
    <div style="margin-top: 20px;">
        <el-table ref="table" :data="tableData" show-summary :summary-method="summaryMethod">
            <el-table-column type="index" width="50" align="center"></el-table-column>
            <!--<el-table-column label="仓库" prop="warName" width="120" align="center"></el-table-column>-->
            <el-table-column label="商户名称" prop="merName" width="300" align="center"></el-table-column>
            <el-table-column label="日运费金额" align="center">
                <el-table-column v-for="index in days" :label="index.toString()" :key="index" align="center">
                    <template slot-scope="scope">
                        {{ scope.row['freightData'][index - 1] ? scope.row['freightData'][index - 1].freight : '' }}
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column label="合计" align="center" width="120">
                <template slot-scope="scope">
                    {{moneyTotal(scope.row)}}
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    //仓库下各商户每月平台运费按天统计（5元/每个客户）
    import {getPlatformMerMonth} from '@/api/delivery/freight'

    export default {
        name: "freightMer",
        data() {
            return {
                days: 31,
                tableData: []
            }
        },
        updated() {
            this.$nextTick(() => {
                this.$refs.table.doLayout();
            })
        },
        methods: {
            /** 每行的合计 */
            moneyTotal({freightData}) {
                let result = 0;
                freightData.forEach(function(arr) {
                    result += (arr.freight || 0);
                });
                return result;
            },
            /** 每列的合计 */
            summaryMethod({ columns, data }) {
                let sums = []; //每列的合计
                let total = 0; //总计的总计
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '';
                    }
                    else if (index === 1) {
                        sums[index] = '总计';
                    } else if (index < this.days + 2) {
                        let count = 0;
                        if (data) {
                            data.forEach(row => {
                                //row = {'freightData':[{'freight': 50}]}
                                let list = row['freightData'];
                                let i = index - 2;
                                if (i < list.length) {
                                    count += list[i]['freight'];
                                }
                            });
                        }
                        sums[index] = count;
                        total += count;
                    }
                });
                sums.push(total);
                return sums;
            },
            getTableData(param, days) {
                getPlatformMerMonth(param).then(({code, data}) => {
                    this.$parent.queryLoading = false;
                    this.days = days;
                    this.tableData = data;
                })
            }
        }
    }
</script>

<style scoped>

</style>
