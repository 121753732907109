<template>
    <div class="app-container">
        <el-form :size="$store.getters.size" :inline="true" :model="queryParams" ref="queryParams">
            <el-form-item>
                <el-select filterable placeholder="选择仓库" v-model="queryParams.warId" clearable >
                    <el-option v-for="(item, index) in warehouseOption" :label="item.label" :key="index" :value="item.value" />
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-date-picker
                        v-model="queryParams.time"
                        type="month"
                        format="yyyy-MM"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                        placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="querySearch" :loading="queryLoading">查询</el-button>
            </el-form-item>
        </el-form>
        <el-row :gutter="1.5">
            <el-col :span="24">
                <el-button :size="$store.getters.size" type="primary" icon="el-icon-download"
                           plain @click="exportExcel" v-if="$app.haveRole('delivery:freight:export')">运费导出</el-button>
                <el-radio-group size="mini" v-model="queryParams.type" style="float: right;margin-right:80px;">
                    <el-radio-button label="mer">按商户统计</el-radio-button>
                    <el-radio-button label="driver">按司机统计</el-radio-button>
                </el-radio-group>
            </el-col>
        </el-row>

        <div id="tableContent" style="width: 100%;">
            <freight-mer ref="freight" v-if="queryParams.type === 'mer'"/>
            <freight-mer-driver ref="freight" v-else-if="queryParams.type === 'driver'"/>
        </div>

    </div>
</template>

<script>
    import {exportPlatformMonthExcel} from '@/api/delivery/freight'
    import freightMerDriver from "./freight_mer_driver";
    import freightMer from "./freight_mer";

    export default {
        name: "platformFreight", //平台运费（分商户统计和司机统计）
        components:{freightMerDriver, freightMer},
        data(){
            return{
                queryParams: {
                    warId: undefined,
                    warName: undefined,
                    time: undefined,
                    type: 'mer'
                },
                warehouseOption: this.$store.getters.warehouse,
                queryLoading: false,
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now() - 8.64e6;  //如果没有后面的-8.64e6就是不可以选择今天的
                    }
                }
            }
        },
        methods: {
            querySearch() {
                if (this.queryLoading) { return; }
                this.queryLoading = true;

                let date = new Date(this.queryParams.time);
                let year = date.getFullYear();
                let month = date.getMonth() + 1;
                let days = new Date(year, month, 0).getDate();
                this.$refs['freight'].getTableData(this.queryParams, days);
            },
            exportExcel() {
                exportPlatformMonthExcel(this.queryParams).then(({code}) => {});
            }
        },
        watch: {
            'queryParams.warId': function (val) {
                if (val) {
                    const data = this.warehouseOption.filter(item => item.value == val);
                    this.queryParams.warName = data[0].label;
                }
            }
        }
    }
</script>

<style scoped>

</style>
