import request from '@/utils/request'

/** 司机运费月报表 */
export function getMonthFreight(param) {
    return request({
        url: '/delivery/freight/month',
        method: 'POST',
        data: param
    })
}

/** 司机运费日报表 */
export function getDateFreight(param) {
    return request({
        url: '/delivery/freight/date',
        method: 'POST',
        data: param
    })
}

export function getFreightMonthSum(param) {
    return request({
        url: '/delivery/freight/month_all_dri',
        method: 'POST',
        data: param
    })
}

/**
 * 获取司机当月开票商户运费
 * @param param
 */
export function getInvoiceMonthDriver(param) {
    return request({
        url: '/delivery/freight/getInvoiceMonthDriver',
        method: 'POST',
        data: param
    })
}

/**
 * 批量修改运费
 */
export function updateFreightAll(param) {
    return request({
        url: '/delivery/freight/update_freight_all',
        method: 'POST',
        data: param
    })
}

/** 导出数据 Excel */
export function exportExcel(param) {
    return request({
        url: '/delivery/freight/exportExcel',
        method: 'GET',
        params: param,
        responseType: 'blob'
    })
}

/**
 * 仓库下各商户每月运费按天统计（基础运费+特殊运费）
 * @param param
 */
export function getWarMerMonth(param) {
    return request({
        url: '/delivery/freight/war_mer_month',
        method: 'POST',
        data: param
    })
}

/**
 * 仓库下各商户付给司机的每月运费按天统计（基础运费+特殊运费）
 * @param param
 */
export function getWarMerDriverMonth(param) {
    return request({
        url: '/delivery/freight/war_mer_driver_month',
        method: 'POST',
        data: param
    })
}

/**
 * 导出仓库下各商户每月运费按天统计Excel（基础运费+特殊运费）
 * @param param
 */
export function exportWarMonthExcel(param) {
    return request({
        url: '/delivery/freight/export_war_month',
        method: 'GET',
        params: param,
        responseType: 'blob'
    })
}

/**
 * 仓库下各商户每月平台运费按天统计（5元/每个客户）
 * @param param
 */
export function getPlatformMerMonth(param) {
    return request({
        url: '/delivery/freight/platform_mer_month',
        method: 'POST',
        data: param
    })
}

/**
 * 仓库下各商户付给司机的每月平台运费按天统计（5元/每个客户）
 * @param param
 */
export function getPlatformMerDriverMonth(param) {
    return request({
        url: '/delivery/freight/platform_mer_driver_month',
        method: 'POST',
        data: param
    })
}

/**
 * 导出仓库下各商户付给司机的每月平台运费按天统计Excel（5元/每个客户）
 * @param param
 */
export function exportPlatformMonthExcel(param) {
    return request({
        url: '/delivery/freight/export_platform_month',
        method: 'GET',
        params: param,
        responseType: 'blob'
    })
}

export function merchantMonthFreight(param) {
    return request({
        url: '/delivery/freight/merchantMonth',
        method: 'POST',
        data: param

    })
}

/**导出Excel */
export function exportExcelInvoice(param) {
    return request({
        url: '/delivery/freight/exportExcelInvoice',
        method: 'GET',
        params: param,
        responseType: 'blob'
    })
}

/** 查询单条运费记录 */
export function getFreight(id) {
    return request({
        url: '/delivery/freight/getFreight',
        method: 'POST',
        data: id
    })
}

/** 修改单条运费记录 */
export function putFreight(param) {
    return request({
        url: '/delivery/freight/putFreight',
        method: 'POST',
        data: param
    })
}